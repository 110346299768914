<template>
    <div class="form-group">
        <div class="box" :style="{'width': img_width, 'height': img_height}" v-if="media.length > 0">
            <img :src="media" :alt="text">
        </div>
        <input type="file" :name="name" @change="previewImage" class="form-control-file top-gap" :accept="accept" v-if="!multiple">
        <input type="file" :name="name" @change="previewImage" class="form-control-file top-gap" :accept="accept" multiple v-else>
    </div>
</template>

<script>
export default {
    name: 'image-input',
    data() {
        return {
            media: this.source || baseUrl + '/img/default.png',
            img_height: this.height,
            img_width: this.width,
            multiple: null,
            accept: 'image/*'
        }
    },
    props: {
        name: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        source: {
            required: false
        },
        height: {
            required: false,
            default: '180px'
        },
        width: {
            required: false,
            default: '180px'
        },
        bulk: {
            required: false
        },
        is_video: {
            required: false
        }
    },
    created() {
        this.multiple = this.bulk ? true : false;
        this.accept = this.is_video === 'true' ? 'image/*,video/*' : 'image/*';
    },
    methods: {
        previewImage(event) {
            let input = event.target;
            if (input.files && input.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.media = e.target.result;
                }
                
                reader.readAsDataURL(input.files[0]);
            } else {
                this.media = null;
            }
        }
    }
}
</script>

<style>
    img {
        max-width: 100%;
        max-height: 100%;
    }
    .box {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(240, 240, 240);
        /* width: 180px;
        height: 180px; */
        border: 1px solid rgb(240, 240, 240);
    }
</style>
