require('./bootstrap');

import ImageInput from './components/ImageInput.vue'; // error when lazy load
const RichTextEditor = () => import('./components/RichTextEditor.vue');
const PostContent = () => import('./partials/PostContent.vue');
const AreaForm = () => import('./partials/AreaForm.vue');
const RestaurantSchedule = () => import('./partials/RestaurantSchedule.vue');
const RestaurantMenu = () => import('./partials/RestaurantMenu.vue');
const RecipeIngredient = () => import('./partials/RecipeIngredient.vue');
const RecipeStep = () => import('./partials/RecipeStep.vue');
import LazyImage from './components/LazyImage.vue';
const SelectForm = () => import('./components/SelectForm.vue');
const HomeBanner = () => import('./components/HomeBanner.vue');
const HomeTabBanner = () => import('./components/HomeTabBanner.vue');
const PostCard = () => import('./components/posts/PostCard.vue');
import RecipeCard from './components/recipes/RecipeCard.vue';
import RestaurantCardV2 from './components/restaurants/RestaurantCardV2.vue';
const FilterPage = () => import('./components/filters/FilterPage.vue');
const PostListContent = () => import('./partials/posts/PostListContent.vue');
import LatestPost from './partials/home/LatestPost.vue';
const SearchButton = () => import('./components/generals/SearchButton.vue');
const RecipeIndex = () => import('./views/recipes/RecipeIndex.vue');
const RestaurantIndex = () => import('./views/restaurants/RestaurantIndex.vue');
const HeaderDetailRestaurant = () => import('./partials/restaurants/HeaderDetailRestaurant.vue');
const ProfileButton = () => import('./components/generals/ProfileButton.vue');
const AddReview = () => import('./partials/restaurants/AddReview.vue');
const ReviewRestaurant = () => import('./partials/restaurants/ListReview.vue');
const MenuCard = () => import('./components/restaurants/MenuCard.vue');
const SaveButton = () => import('./components/restaurants/SaveButton.vue');
const AddPhoto = () => import('./components/restaurants/AddPhoto.vue');
const ListMenu = () => import('./partials/restaurants/ListMenu.vue');
const ReviewRecipe = () => import('./partials/recipes/ListReview.vue');
const ReviewPost = () => import('./partials/posts/ListReview.vue');
const AddThread = () => import('./components/restaurants/AddThread.vue');
const AnswerThread = () => import('./components/restaurants/AnswerThread.vue');
import VideoFrame from './components/recipes/VideoFrame.vue';
const LoginForm = () => import('./views/auth/LoginForm.vue');
const LogoutButton = () => import('./views/auth/LogoutButton.vue');
const RecipeForm = () => import('./views/recipes/RecipeForm.vue');
const ImageZoomer = () => import('./components/generals/ImageZoomer.vue');
const SectionContentForms = () => import('./partials/sections/SectionContentForms.vue');
const CampaignItemForm = () => import('./partials/campaigns/CampaignItemForm.vue');
const CampaignSeoKeywords = () => import('./components/campaigns/CampaignSeoKeywords.vue');
const CampaignList = () => import('./partials/campaigns/CampaignList.vue');
const CampaignDetail = () => import('./partials/campaigns/CampaignDetail.vue');
const AutoComplete = () => import('./components/AutoComplete.vue');
const ButtonToTop = () => import('./components/ButtonToTop.vue');

new Vue({
    el: "#app",
    components: {
        RichTextEditor,
        PostContent,
        ImageInput,
        AreaForm,
        RestaurantSchedule,
        RestaurantMenu,
        LazyImage,
        RecipeIngredient,
        RecipeStep,
        SelectForm,
        HomeBanner,
        HomeTabBanner,
        PostCard,
        RecipeCard,
        RestaurantCardV2,
        FilterPage,
        PostListContent,
        LatestPost,
        SearchButton,
        RecipeIndex,
        RestaurantIndex,
        HeaderDetailRestaurant,
        ProfileButton,
        AddReview,
        MenuCard,
        SaveButton,
        AddPhoto,
        ListMenu,
        ReviewRestaurant,
        ReviewRecipe,
        ReviewPost,
        AddThread,
        AnswerThread,
        VideoFrame,
        LoginForm,
        LogoutButton,
        RecipeForm,
        ImageZoomer,
        SectionContentForms,
        CampaignItemForm,
        CampaignSeoKeywords,
        CampaignList,
        CampaignDetail,
        AutoComplete,
        ButtonToTop,
    }
});
