<template>
    <div>
        <div v-if="loading">
            <div class="text-center" :style="spinner">
                <b-spinner label="Loading..."></b-spinner>
            </div>
        </div>

        <div v-show="!loading">
            <div class="card-grid">
                <a :href="data.route">
                    <div class="header-content recipe">
                        <lazy-image :source="data.image" type="sm-1"></lazy-image>
                    </div>
                    <div class="body-content tblck">
                        <p class="title-restaurant">{{ data.title }}</p>
                        <div class="mt-1">
                            <star-component :rating="data.rating" :review="data.total_review"></star-component>
                        </div>
                        <div class="estimate">
                            {{ data.location }} • 
                            <span class="badge badge-pill badge-category badge-restaurant fs-12px">{{ data.category }}</span>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import LazyImage from '../LazyImage.vue';
import { BSpinner } from 'bootstrap-vue';
import StarComponent from '../Stars.vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';

export default {
    name: 'restaurant-card-v2',
    components: {
        LazyImage,
        BSpinner,
        StarComponent
    },
    data() {
        return {
            loading: true,
            data: {
                title: null,
                route: null,
                image: null,
                category: null,
                rating: 0,
                total_review: 0,
                location: null
            },
            spinner: {
                'height': '323px',
                'display': 'flex',
                'align-items': 'center',
                'justify-content': 'center'
            }
        }
    },
    props: {
        list: {
            required: true
        }
    },
    created() {
        this.handleData();
    },
    methods: {
        timer() {
            setTimeout(() => {
                this.loading = false;
            }, 200);
        },
        handleData() {
            if(this.list) {
                const data = typeof this.list == 'string' ? JSON.parse(this.list) : this.list;
                this.data.title = data.title ? data.title : null;
                this.data.route = data.route ? data.route : null;
                this.data.image = data.image ? data.image : data.images ? data.images[0] : null;
                this.data.category = data.category ? data.category : null;
                this.data.rating = data.rate_avg ? data.rate_avg : 0;
                this.data.total_review = data.total_review ? data.total_review : 0;
                this.data.location = data.location ? data.location : null;
            }

            this.timer();
        }
    }
}
</script>

<style scoped>
    .header-content .recipe {
        height: 265px
    }

    .title-restaurant {
        font-size: 20px;
        font-weight: 600;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        word-wrap: break-word;
        overflow: hidden;
        color: var(--dark);
        margin-top: 6px;
        margin-bottom: 0;
        line-height: 27px;
    }

    @media screen and (max-width: 540px) {
        .title-restaurant {
            font-size: 14px !important;
            line-height: 21px !important;
            margin-top: 2px !important;
        }
    }

    .badge-restaurant {
        color: #01962e;
        background-color: #d7ffe3;
    }

    .tblck {
        color: black;
    }
</style>
