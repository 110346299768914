<template>
    <div>
        <div v-if="loading">
            <div class="text-center" :style="spinner">
                <b-spinner label="Loading..."></b-spinner>
            </div>
        </div>

        <div v-else>
            <b-embed type="video" :style="style" aspect="16by9" controls>
                <source :src="getVideoUrl()" type="video/webm">
                <source :src="getVideoUrl()" type="video/mp4">
                <source :src="getVideoUrl()" type="video/mov">
            </b-embed>
        </div>
    </div>
</template>

<script>
    import { BEmbed, BSpinner } from 'bootstrap-vue';
    import 'bootstrap-vue/dist/bootstrap-vue.css';

    export default {
        name: 'video-frame',
        components: {
            BEmbed,
            BSpinner
        },
        data() {
            return {
                loading: true,
                spinner: {
                    'display': 'flex',
                    'align-items': 'center',
                    'justify-content': 'center',
                    'height': '485px'
                },
                style: {
                    'height': 'auto',
                    'width': '100%',
                    'position': 'absolute',
                    'object-fit': 'cover',
                    'border-radius': '15px'
                }
            }
        },
        props: {
            source: {
                required: true
            }
        },
        created() {
            this.timer();
        },
        methods: {
            timer() {
                setTimeout(() => {
                    this.loading = false;
                }, 1000);
            },
            getVideoUrl() {
                return `${baseUrl}/${this.source}`;
            }
        }
    }
</script>
