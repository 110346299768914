<template>
    <div>
        <div v-if="loading">
            <div class="text-center" :style="spinner">
                <b-spinner label="Loading..."></b-spinner>
            </div>
        </div>

        <div v-show="!loading">
            <div class="card-grid">
                <a :href="data.route">
                    <div class="header-content recipe">
                        <video-frame v-if="data.media_type == 'video'" :source="data.image"></video-frame>
                        <lazy-image v-else :source="data.image" type="sm-1"></lazy-image>
                    </div>
                    <div class="body-content">
                        <span class="badge badge-pill badge-category fs-12px">{{ data.category }}</span>
                        <p class="title-recipe">{{ data.title }}</p>
                        <div class="estimate">
                            <i class="fas fa-stopwatch fa-fw text-danger mr-4px"></i>{{ data.duration }} Menit • <i class="fas fa-user fa-fw text-danger mr-4px"></i>{{ data.serves }} Porsi • <i class="fas fa-utensils fa-fw text-danger mr-4px"></i>{{ data.difficulty }}
                        </div>
                        <div class="user-name">
                            <i class="fas fa-user fa-fw text-info"></i>
                            {{ data.author }}
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import LazyImage from '../LazyImage.vue';
import { BSpinner } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VideoFrame from './VideoFrame.vue';

export default {
    name: 'recipe-card',
    components: {
        LazyImage,
        BSpinner,
        VideoFrame
    },
    data() {
        return {
            loading: true,
            data: {
                title: null,
                route: null,
                image: null,
                category: null,
                duration: null,
                serves: null,
                difficulty: null,
                author: null,
                media_type: null
            },
            spinner: {
                'height': '323px',
                'display': 'flex',
                'align-items': 'center',
                'justify-content': 'center'
            }
        }
    },
    props: {
        list: {
            required: true
        }
    },
    created() {
        this.handleData();
    },
    methods: {
        timer() {
            this.loading = false;
        },
        handleData() {
            if(this.list) {
                const data = typeof this.list == 'string' ? JSON.parse(this.list) : this.list;
                this.data.title = data.title ? data.title : null;
                this.data.route = data.route ? data.route : null;
                this.data.image = data.image ? data.image : null;
                this.data.category = data.category ? data.category : null;
                this.data.duration = data.duration ? data.duration : null;
                this.data.serves = data.serves ? data.serves : null;
                this.data.difficulty = data.difficulty ? data.difficulty : null;
                this.data.author = data.author ? data.author : null;
                this.data.media_type = data.media_type ? data.media_type : null;
            }

            this.timer();
        }
    }
}
</script>

<style>
    .header-content .recipe {
        height: 265px
    }
</style>
