<template>
  <div>
    <div class="d-flex flex-row align-items-center">
      <small class="mr-1 text-danger"><b>{{ original_rates }}</b></small>
      <span v-for="(rv, i) in rates" :key="`${rv}${i}`" class="badge badge-danger p-0-5 mr-0-5">
          <i class="fas fa-star fa-sm text-white"></i>
      </span>
      <span v-for="no in 5-rates" :key="`no-${no}`" class="badge badge-secondary p-0-5 mr-0-5">
          <i class="fas fa-star fa-sm text-white"></i>
      </span>
      <small class="d-none d-sm-block ml-0">({{ total_review }})</small>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'star-component',
    data() {
      return {
        rates: 0,
        original_rates: 0.0,
        total_review: 0
      }
    },
    props: {
      rating: {
        required: true
      },
      review: {
        required: true
      }
    },
    created() {
      this.handleData();
    },
    methods: {
      handleData() {
        this.rates = parseInt(this.rating),
        this.original_rates = parseFloat(this.rating)
        this.total_review = this.review;
      }
    }
  }
</script>

<style scoped>
  .p-0-5 {
    padding: 1.5%;
  }

  .mr-0-5 {
    margin-right: 1%;
  }
</style>
