<template>
    <div>
        <div class="clearfix mb-8px">
            <div class="float-left max-width-title-mobile">
                <h2 class="title-h2">{{ content.title }}</h2>
                <p class="subtitle-h2">{{ content.subtitle }}</p>
            </div>
        </div>

        <div class="row padding-grid">
            <div v-for="item in data" :key="item.id" class="col-12 padding-grid">
                <a :href="item.route">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-5 col-5">
                            <lazy-image :source="item.image" type="sm-1"></lazy-image>
                        </div>
                        <div class="col-lg-9 col-md-8 col-sm-7 col-7">
                            <div class="body-content tblck">
                                <span class="badge badge-round badge-colored">
                                    {{ item.type }}
                                </span>
                                <p class="title-post" :title="item.title">{{ item.title }}</p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>

        <div v-if="is_next">
            <p role="button" class="see-more" style="margin-top: 25px;" @click="handleData(page)">Show more</p>
        </div>
    </div>
</template>

<script>
import LazyImage from '../../components/LazyImage';

export default {
    name: 'latest-post',
    components: {
        LazyImage,
    },
    data() {
        return {
            content: {
                title: null,
                subtitle: null
            },
            data: [],
            page: 1,
            is_next: true
        }
    },
    props: {
        title: String,
        subtitle: String
    },
    created() {
        this.handleContent();
        this.handleData(this.page);
    },
    methods: {
        async handleData(page) {
            let per_page = 8;
            if(window.innerWidth <= 768) {
                per_page = 6;
            }

            const result = await axios({
                method: 'get',
                url: `${baseUrl}/api/latest-post?page=${page}&per_page=${per_page}`
            });

            const data = result.data.data;

            if(data.length < per_page) {
                this.is_next = false;
            }

            data.forEach(v => this.data.push(v));
            this.page = this.page + 1;
        },
        handleContent() {
            this.content.title = this.title ? this.title : '';
            this.content.subtitle = this.subtitle ? this.subtitle : '';
        }
    }
}
</script>

<style scoped>
    .title-post {
        font-size: 24px;
        font-weight: 600;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        word-wrap: break-word;
        overflow: hidden;
        color: var(--dark);
        margin-top: 6px;
        margin-bottom: 0;
        line-height: 32px;
    }

    .badge-colored {
        color: #d6f9ff;
        background-color: blue;
        font-size: 14px;
        padding: 8px 12px;
    }

    @media screen and (max-width: 768px) {
        .title-post {
            font-size: 20px;
            line-height: 30px;
            margin-top: 4px;
        }
    }

    @media screen and (max-width: 540px) {
        .title-post {
            font-size: 16px !important;
            line-height: 28px !important;
            margin-top: 2px !important;
        }

        .badge-colored {
            font-size: 12px;
            padding: 5px 7px;
        }
    }

    .tblck {
        color: black;
    }
</style>
