<template>
    <div>
        <div v-if="loading">
            <div class="text-center" :style="spinner">
                <b-spinner label="Loading..."></b-spinner>
            </div>
        </div>

        <div v-show="!loading">
            <b-img-lazy v-bind="mainProps" :style="style" :src="getImageUrl()" :alt="label"></b-img-lazy>
        </div>
    </div>
</template>

<script>
import { BImgLazy, BSpinner } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';

export default {
    name: 'lazy-image',
    components: {
        BImgLazy,
        BSpinner
    },
    data() {
        return {
            loading: true,
            mainProps: {
                center: true,
                fluidGrow: true,
                blank: true,
                blankColor: '#bbb'
            },
            style: {
                'border-radius': '15px',
                'object-fit': 'cover'
            },
            spinner: {
                'display': 'flex',
                'align-items': 'center',
                'justify-content': 'center'
            }
        }
    },
    props: {
        source: String,
        label: {
            type: String,
            required: false,
            default: 'Image'
        },
        type: String
    },
    created() {
        this.onResize();
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        timer() {
            setTimeout(() => {
                this.loading = false;
            }, 200);
        },
        getImageUrl() {
            return this.source ? `${baseUrl}/${this.source}` : null;
        },
        onResize() {
            switch(this.type) {
            case 'sm':
                this.style.height = window.innerWidth <= 465 ? '34px' : '48px';
                this.style['border-radius'] = window.innerWidth <= 465 ? '2px' : '5px';
                this.spinner.height = window.innerWidth <= 465 ? '34px' : '48px';
                break;
            case 'sm-1':
                this.style.height = window.innerWidth <= 465 ? '120px' : '200px';
                this.style['border-radius'] = window.innerWidth <= 465 ? '4px' : '8px';
                this.spinner.height = window.innerWidth <= 465 ? '120px' : '200px';
                break;
            case 'md':
                this.style.height = window.innerWidth <= 465 ? '152px' : '265px';
                this.style['border-radius'] = window.innerWidth <= 465 ? '5px' : '10px';
                this.spinner.height = window.innerWidth <= 465 ? '152px' : '265px';
                break;
            case 'md-1':
                this.style.height = window.innerWidth <= 465 ? '192px' : '325px';
                this.style['border-radius'] = window.innerWidth <= 465 ? '6px' : '11px';
                this.spinner.height = window.innerWidth <= 465 ? '192px' : '325px';
                break;
            case 'lg':
                this.style.height = window.innerWidth <= 465 ? '262px' : '485px';
                this.style['border-radius'] = window.innerWidth <= 465 ? '7px' : '15px';
                this.spinner.height = window.innerWidth <= 465 ? '262px' : '485px';
                break;
            default:
                this.style.height = window.innerWidth <= 465 ? '152px' : '265px';
                this.style['border-radius'] = window.innerWidth <= 465 ? '5px' : '10px';
                this.spinner.height = window.innerWidth <= 465 ? '152px' : '265px';
                break;
            }

            this.timer();
        }
    }
}
</script>
