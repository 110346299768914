window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.Vue = require("vue").default;

if(process.env.NODE_ENV === 'development') {
    window.baseUrl = process.env.MIX_APP_URL_DEV;
} else if(process.env.NODE_ENV === 'production') {
    window.baseUrl = process.env.MIX_APP_URL_PROD;
} else {
    window.baseUrl = process.env.MIX_APP_URL;
}

window.binderbyteKey = process.env.MIX_BINDERBYTE_KEY;

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
